:root {
  --color_text_content: #585858;
  --color_xam_2: #e5e5e5;
  --color_button: #b03014;
}

* {
  /* font-size: 16px; */
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  margin: 0;
}

.button_td {
  background: var(--color_button);
  color: white;
  padding: 12px 20px;
  border-radius: 5px;
  font-weight: bold;
}
.color_xam_2 {
  color: var(--color_xam_2);
}
.color_text_content {
  color: var(--color_text_content);
}
.header_td {
  padding: 30px 0px;
}
.section {
  padding: 90px 0px;
}
.flag {
  width: 32px;
  height: 20px;
}
.xxl-text {
  font-size: 120px !important;
  font-weight: bold;
  color: white;
  line-height: 110px;
  letter-spacing: -3px;
}
.xsl-text {
  font-size: 73px;
  font-weight: bold;
  color: white;
  line-height: 70px;
}
.xl-text {
  font-size: 60px;
  font-weight: bold;
  color: white;
  line-height: 80px;
}
.l-text {
  font-size: 37px;
  font-weight: 300;
  color: white;
}
.m-text {
  font-size: 20px;
  font-weight: 300;
  color: white;
}
.number-count-xl {
  min-width: 270px;
  display: inline-block;
}
.number-count-l {
  min-width: 73px;
  display: inline-block;
}
.number-count-m {
  min-width: 80px;
  /* display: inline-block; */
}
.container_td {
  max-width: 1440px;
  width: 80%;
  margin: 0 auto;
}

.grid_td {
  @apply grid grid-cols-12;
}

.banner_td {
  width: 100%;
  height: 100vh;
}

/* Lĩnh vực ứng dụng */

.img_ungdung {
}

.grid_td_mobile {
  @apply grid grid-cols-4;
}

@media only screen and (max-width: 639px) {
  p {
    font-size: 12px;
  }
  .text-3xl p {
    @apply text-xl;
  }
  .container_td {
    max-width: 375px;
    width: 90%;
    margin: 0 auto;
  }
  .section {
    padding: 40px 0px;
  }
  .xxl-text {
    font-size: 70px !important;
    font-weight: bold;
    color: white;
    line-height: 55px;
    letter-spacing: -1px;
  }
  .xsl-text {
    font-size: 50px;
    font-weight: bold;
    color: white;
    line-height: 45px;
  }
  .l-text p {
    font-size: 20px;
    color: white;
  }
  .xl-text p {
    font-size: 32px !important;
    font-weight: bold;
    color: white;
    line-height: 40px;
  }
  .m-text {
    font-size: 12px;
    font-weight: 300;
    color: white;
  }
  .number-count-xl {
    min-width: 150px;
    display: inline-block;
  }
  .number-count-l {
    min-width: 73px;
    display: inline-block;
  }
  .number-count-m {
    min-width: 80px;
    /* display: inline-block; */
  }
  .banner_td {
    width: 100%;
    height: 85vh;
  }

  .grid_td {
    @apply grid grid-cols-4;
  }
  .button_td {
    background: var(--color_button);
    color: white;
    padding: 8px 20px;
    border-radius: 10px;
    font-weight: bold;
    text-align: center;
  }
}
